export interface TreatmentData {
    image: string;
    title: string;
    description: string;
    symptoms: string[];
    causes: string[];
    treatments: string[];
    estimatedDuration: string;
    followUpCare: string;
    slug: string;
    instagramLink: string;
    pdfResource?: {
        url: string;
        title: string;
    },
    ytLink?: string; 
}

const treatmentsData: TreatmentData[] = [
    {
        image: "/images/treatments/diabetes.jpeg",
        title: "Gestational Diabetes",
        description: "Management of diabetes that develops during pregnancy and affects how your cells use sugar (glucose).",
        symptoms: [
            "Usually no obvious symptoms",
            "Increased thirst",
            "Frequent urination",
            "Fatigue",
            "Nausea",
            "Blurred vision"
        ],
        causes: [
            "Hormonal changes during pregnancy",
            "Placental hormones that block insulin action",
            "Family history of diabetes",
            "Being overweight before pregnancy",
            "Advanced maternal age (over 35)",
            "Previous gestational diabetes"
        ],
        treatments: [
            "Blood sugar monitoring",
            "Dietary modifications",
            "Regular exercise",
            "Insulin therapy (if needed)",
            "Regular prenatal check-ups",
            "Lifestyle counseling"
        ],
        estimatedDuration: "Typically lasts throughout pregnancy and resolves after delivery. Requires monitoring for type 2 diabetes afterward.",
        followUpCare: "Regular blood sugar monitoring during pregnancy, postpartum glucose testing at 6-12 weeks, and annual diabetes screening thereafter.",
        slug: "gestational-diabetes",
        instagramLink: "https://www.instagram.com/p/DCWH8o6vV4u/",
        pdfResource: {
            url: "/GESTATIONAL-DIABETES.pdf",
            title: "Gestational Diabetes Information Guide"
        }
    },
    {
        image: "/images/treatments/growth-issues.jpg",
        title: "Growth Issues - Height & Weight",
        description: "Diagnosis and management of growth-related concerns in children and adolescents.",
        symptoms: [
            "Short stature compared to peers",
            "Slow growth rate",
            "Delayed puberty",
            "Underweight or overweight for age",
            "Disproportionate body parts"
        ],
        causes: [
            "Genetic factors",
            "Hormonal imbalances (e.g., growth hormone deficiency)",
            "Nutritional deficiencies",
            "Chronic illnesses",
            "Certain medications",
            "Environmental factors"
        ],
        treatments: [
            "Growth hormone therapy",
            "Nutritional interventions",
            "Treatment of underlying medical conditions",
            "Puberty induction (if needed)",
            "Regular monitoring and follow-up"
        ],
        estimatedDuration: "Treatment duration varies, often continuing until growth is complete or desired height is achieved, which can be several years.",
        followUpCare: "Regular check-ups to monitor growth progress, bone age assessments, and adjustments to treatment plan as needed. Typically, follow-ups occur every 3-6 months.",
        slug: "growth-issues",
        instagramLink: "https://www.instagram.com/p/CUCUPMepBJL/"
    },
    {
        image: "/images/treatments/diabetes-mellitus.jpg",
        title: "Diabetes Mellitus",
        description: "Management of blood sugar levels and associated complications in diabetic patients.",
        symptoms: [
            "Increased thirst and frequent urination",
            "Unexplained weight loss",
            "Fatigue",
            "Blurred vision",
            "Slow-healing wounds"
        ],
        causes: [
            "Type 1: Autoimmune destruction of insulin-producing cells",
            "Type 2: Insulin resistance and/or decreased insulin production",
            "Genetic predisposition",
            "Obesity (for Type 2)",
            "Environmental factors"
        ],
        treatments: [
            "Insulin therapy (injections or pump)",
            "Oral medications (for Type 2)",
            "Blood glucose monitoring",
            "Dietary management",
            "Regular exercise",
            "Education on diabetes self-management"
        ],
        estimatedDuration: "Lifelong management is typically required, with ongoing adjustments to treatment plans.",
        followUpCare: "Regular check-ups every 3-6 months, annual screenings for complications, and continuous self-monitoring of blood glucose levels.",
        slug: "diabetes-mellitus",
        instagramLink: '',
        ytLink: 'HReU0iMjpFY'
    },
    {
        image: "/images/treatments/thyroid-disorders.jpg",
        title: "Thyroid Disorders",
        description: "Diagnosis and treatment of conditions affecting thyroid function.",
        symptoms: [
            "Unexplained weight changes",
            "Fatigue or restlessness",
            "Mood changes",
            "Changes in heart rate",
            "Sensitivity to cold or heat"
        ],
        causes: [
            "Autoimmune diseases (e.g., Hashimoto's, Graves' disease)",
            "Iodine deficiency or excess",
            "Thyroid nodules or cancer",
            "Certain medications",
            "Radiation therapy"
        ],
        treatments: [
            "Hormone replacement therapy (for hypothyroidism)",
            "Anti-thyroid medications (for hyperthyroidism)",
            "Radioactive iodine therapy",
            "Thyroid surgery (in some cases)",
            "Dietary adjustments"
        ],
        estimatedDuration: "Treatment is often lifelong, particularly for autoimmune thyroid disorders.",
        followUpCare: "Regular blood tests to monitor thyroid hormone levels, typically every 6-12 months once stable.",
        slug: "thyroid-disorders",
        instagramLink: "https://www.instagram.com/p/CTMMN23jHl-/"
    },
    {
        image: "/images/treatments/puberty.jpg",
        title: "Puberty - Early/Delayed",
        description: "Management of early or delayed onset of puberty in adolescents.",
        symptoms: [
            "Early puberty: Development of secondary sexual characteristics before age 8 in girls or 9 in boys",
            "Delayed puberty: Lack of pubertal changes by age 13 in girls or 14 in boys",
            "Growth spurts (early) or lack thereof (delayed)",
            "Emotional and behavioral changes"
        ],
        causes: [
            "Genetic factors",
            "Hormonal imbalances",
            "Brain or pituitary gland abnormalities",
            "Chronic illnesses",
            "Nutritional factors",
            "Environmental influences"
        ],
        treatments: [
            "Monitoring without intervention (in some cases)",
            "Hormone therapy to delay puberty (for early puberty)",
            "Hormone replacement therapy (for delayed puberty)",
            "Treatment of underlying conditions",
            "Psychological support"
        ],
        estimatedDuration: "Treatment duration varies, often continuing until normal pubertal development is achieved or completed.",
        followUpCare: "Regular check-ups to monitor pubertal progress, typically every 3-6 months during active treatment.",
        slug: "puberty",
        instagramLink: "https://www.instagram.com/p/CQ-tnm8AWFY/"
    },
    {
        image: "/images/treatments/obesity.jpg",
        title: "Obesity",
        description: "Comprehensive management of excessive body weight and associated health risks.",
        symptoms: [
            "Body Mass Index (BMI) of 30 or higher",
            "Excess body fat, especially around the waist",
            "Shortness of breath",
            "Joint pain",
            "Fatigue"
        ],
        causes: [
            "Poor diet and eating habits",
            "Lack of physical activity",
            "Genetic factors",
            "Certain medical conditions (e.g., hypothyroidism, PCOS)",
            "Medications",
            "Psychological factors"
        ],
        treatments: [
            "Lifestyle modifications (diet and exercise)",
            "Behavioral therapy",
            "Medications for weight loss (in some cases)",
            "Bariatric surgery (for severe cases)",
            "Management of associated health conditions"
        ],
        estimatedDuration: "Long-term management is typically required, with ongoing lifestyle changes.",
        followUpCare: "Regular check-ups to monitor weight, BMI, and associated health markers. Frequency varies based on individual needs and treatment plan.",
        slug: "obesity",
        instagramLink: "https://www.instagram.com/p/C4FwmPWp9-8/"
    },
    {
        image: "/images/treatments/cah.jpg",
        title: "Congenital Adrenal Hyperplasia (CAH)",
        description: "Management of inherited disorder affecting adrenal gland function.",
        symptoms: [
            "Ambiguous genitalia in newborns",
            "Early or delayed puberty",
            "Rapid growth in childhood but shorter adult height",
            "Acne, excessive body hair",
            "Infertility"
        ],
        causes: [
            "Genetic mutations affecting enzymes involved in cortisol production",
            "Most commonly, 21-hydroxylase deficiency",
            "Autosomal recessive inheritance pattern"
        ],
        treatments: [
            "Hormone replacement therapy (cortisol and sometimes aldosterone)",
            "Management of electrolyte imbalances",
            "Surgical correction of genital abnormalities (in some cases)",
            "Psychological support",
            "Genetic counseling for families"
        ],
        estimatedDuration: "Lifelong management is required.",
        followUpCare: "Regular check-ups to monitor hormone levels, growth, and overall health. Frequency may be every 3-6 months or as determined by the endocrinologist.",
        slug: "cah",
        instagramLink: "https://www.instagram.com/explore/tags/cah"
    },
    {
        image: "/images/treatments/adrenal-gland-dysfunction.jpg",
        title: "Adrenal Gland Dysfunction",
        description: "Diagnosis and treatment of disorders affecting adrenal gland function.",
        symptoms: [
            "Fatigue",
            "Weakness",
            "Weight changes",
            "Changes in blood pressure",
            "Mood swings"
        ],
        causes: [
            "Autoimmune disorders",
            "Infections",
            "Tumors",
            "Genetic disorders",
            "Medications (e.g., long-term steroid use)"
        ],
        treatments: [
            "Hormone replacement therapy",
            "Treatment of underlying causes",
            "Lifestyle modifications",
            "Regular monitoring of hormone levels"
        ],
        estimatedDuration: "Treatment duration varies depending on the underlying cause, often requiring long-term management.",
        followUpCare: "Regular check-ups to monitor hormone levels and adjust treatment as needed, typically every 3-6 months.",
        slug: "adrenal-gland-dysfunction",
        instagramLink: "https://www.instagram.com/explore/tags/adrenaldisorders"
    },
    {
        image: "/images/treatments/pcos-pcod.jpg",
        title: "PCOS/PCOD",
        description: "Management of Polycystic Ovary Syndrome and associated symptoms.",
        symptoms: [
            "Irregular menstrual cycles",
            "Excessive hair growth",
            "Acne",
            "Weight gain",
            "Difficulty getting pregnant"
        ],
        causes: [
            "Hormonal imbalances",
            "Insulin resistance",
            "Genetic factors",
            "Obesity"
        ],
        treatments: [
            "Lifestyle modifications (diet and exercise)",
            "Hormonal birth control",
            "Metformin for insulin resistance",
            "Anti-androgen medications",
            "Fertility treatments (if needed)"
        ],
        estimatedDuration: "Long-term management is typically required.",
        followUpCare: "Regular check-ups to monitor symptoms and hormone levels, usually every 3-6 months.",
        slug: "pcos-pcod",
        instagramLink: "https://www.instagram.com/p/CRPH0ZMJpz9/"
    },
    {
        image: "/images/treatments/dsd-abnormal-genitalia.png",
        title: "DSD/Abnormal Genitalia",
        description: "Diagnosis and management of Disorders of Sex Development.",
        symptoms: [
            "Ambiguous genitalia at birth",
            "Mismatched internal and external sex organs",
            "Atypical development during puberty"
        ],
        causes: [
            "Genetic mutations",
            "Hormonal imbalances during fetal development",
            "Chromosomal abnormalities"
        ],
        treatments: [
            "Hormone therapy",
            "Surgical interventions (in some cases)",
            "Psychological support",
            "Genetic counseling"
        ],
        estimatedDuration: "Treatment and support are often lifelong.",
        followUpCare: "Regular medical and psychological check-ups, frequency determined by individual needs.",
        slug: "dsd-abnormal-genitalia",
        instagramLink: "https://www.instagram.com/explore/tags/dsd"
    },
    {
        image: "/images/treatments/pituitary-gland-dysfunction.jpg",
        title: "Pituitary Gland Dysfunction",
        description: "Management of disorders affecting the pituitary gland and its hormone production.",
        symptoms: [
            "Growth abnormalities",
            "Sexual dysfunction",
            "Fatigue",
            "Changes in vision",
            "Headaches"
        ],
        causes: [
            "Tumors (benign or malignant)",
            "Head injuries",
            "Autoimmune conditions",
            "Genetic disorders",
            "Radiation therapy"
        ],
        treatments: [
            "Hormone replacement therapy",
            "Tumor removal surgery (if applicable)",
            "Radiation therapy (for some tumors)",
            "Management of underlying conditions"
        ],
        estimatedDuration: "Often requires lifelong management and monitoring.",
        followUpCare: "Regular hormone level tests and imaging studies, typically every 3-6 months.",
        slug: "pituitary-gland-dysfunction",
        instagramLink: "https://www.instagram.com/explore/tags/pituitarydisorders"
    },
    {
        image: "/images/treatments/hypoglycemia.webp",
        title: "Hypoglycemia",
        description: "Diagnosis and management of low blood sugar levels.",
        symptoms: [
            "Shakiness",
            "Sweating",
            "Confusion",
            "Rapid heartbeat",
            "Dizziness"
        ],
        causes: [
            "Diabetes medications",
            "Excessive alcohol consumption",
            "Severe liver or kidney disease",
            "Endocrine disorders",
            "Tumors (rare)"
        ],
        treatments: [
            "Immediate treatment with fast-acting carbohydrates",
            "Adjustment of diabetes medications (if applicable)",
            "Treatment of underlying conditions",
            "Education on recognizing and preventing hypoglycemia"
        ],
        estimatedDuration: "Acute management for episodes; ongoing management if due to chronic condition.",
        followUpCare: "Regular check-ups to monitor blood sugar levels and adjust treatment plan as needed.",
        slug: "hypoglycemia",
        instagramLink: "https://www.instagram.com/explore/tags/hypoglycemia"
    },
    {
        image: "/images/treatments/hypergonadism.jpg",
        title: "Hypogonadism",
        description: "Management of reduced or absent sex hormone production.",
        symptoms: [
            "Delayed puberty",
            "Reduced libido",
            "Infertility",
            "Fatigue",
            "Reduced muscle mass"
        ],
        causes: [
            "Genetic disorders",
            "Injury to testes or ovaries",
            "Pituitary gland disorders",
            "Chronic diseases",
            "Aging"
        ],
        treatments: [
            "Hormone replacement therapy",
            "Treatment of underlying conditions",
            "Fertility treatments (if desired)",
            "Psychological support"
        ],
        estimatedDuration: "Often requires lifelong management.",
        followUpCare: "Regular hormone level tests and physical exams, typically every 3-6 months.",
        slug: "hypogonadism",
        instagramLink: "https://www.instagram.com/explore/tags/hypogonadism"
    },
    {
        image: "/images/treatments/diabetes-insipidus.jpeg",
        title: "Diabetes Insipidus",
        description: "Management of rare condition affecting water balance in the body.",
        symptoms: [
            "Excessive thirst",
            "Producing large amounts of urine",
            "Dehydration",
            "Fatigue",
            "Dry skin"
        ],
        causes: [
            "Damage to the pituitary gland or hypothalamus",
            "Genetic disorders",
            "Certain medications",
            "Chronic kidney disease"
        ],
        treatments: [
            "Desmopressin (synthetic vasopressin)",
            "Treating underlying conditions",
            "Careful fluid intake management",
            "Thiazide diuretics (in some cases)"
        ],
        estimatedDuration: "Often requires lifelong management.",
        followUpCare: "Regular check-ups to monitor fluid balance and electrolyte levels, typically every 3-6 months.",
        slug: "diabetes-insipidus",
        instagramLink: "https://www.instagram.com/explore/tags/diabetesinsipidus"
    },
    {
        image: "/images/treatments/endocrine-bone-diseases.jpg",
        title: "Endocrine Bone Diseases",
        description: "Diagnosis and treatment of bone disorders related to endocrine dysfunction.",
        symptoms: [
            "Bone pain",
            "Increased fracture risk",
            "Height loss",
            "Skeletal deformities",
            "Muscle weakness"
        ],
        causes: [
            "Hyperparathyroidism",
            "Vitamin D deficiency",
            "Cushing's syndrome",
            "Hypogonadism",
            "Thyroid disorders"
        ],
        treatments: [
            "Hormone replacement therapy",
            "Vitamin D and calcium supplementation",
            "Bisphosphonates",
            "Treatment of underlying endocrine disorders",
            "Lifestyle modifications (diet and exercise)"
        ],
        estimatedDuration: "Treatment duration varies, often requiring long-term management.",
        followUpCare: "Regular bone density scans and hormone level tests, typically annually or as recommended.",
        slug: "endocrine-bone-diseases",
        instagramLink: "https://www.instagram.com/explore/tags/endocrinebonediseases"
    },
    {
        image: "/images/treatments/neuroendocrine-disorders.webp",
        title: "Neuroendocrine Disorders",
        description: "Management of conditions affecting the interaction between the nervous system and endocrine glands.",
        symptoms: [
            "Varied, depending on the specific disorder",
            "May include hormone imbalances",
            "Neurological symptoms",
            "Mood changes",
            "Metabolic disturbances"
        ],
        causes: [
            "Tumors (e.g., pituitary adenomas)",
            "Genetic disorders",
            "Autoimmune conditions",
            "Infections affecting the hypothalamus or pituitary"
        ],
        treatments: [
            "Hormone replacement therapy",
            "Tumor removal surgery (if applicable)",
            "Radiation therapy",
            "Medications to control hormone production",
            "Management of neurological symptoms"
        ],
        estimatedDuration: "Often requires lifelong management and monitoring.",
        followUpCare: "Regular hormone level tests and imaging studies, frequency determined by the specific disorder.",
        slug: "neuroendocrine-disorders",
        instagramLink: "https://www.instagram.com/explore/tags/neuroendocrine"
    },
    {
        image: "/images/treatments/syndromes-turner-klinefelter.jpg",
        title: "Syndromes - Turner, Klinefelter",
        description: "Diagnosis and management of genetic disorders affecting sex chromosomes.",
        symptoms: [
            "Turner: Short stature, delayed puberty in girls",
            "Klinefelter: Tall stature, reduced fertility in males",
            "Both may have learning difficulties",
            "Hormonal imbalances"
        ],
        causes: [
            "Turner: Missing or incomplete X chromosome in females",
            "Klinefelter: Extra X chromosome in males"
        ],
        treatments: [
            "Growth hormone therapy",
            "Sex hormone replacement therapy",
            "Fertility treatments (if desired)",
            "Educational support",
            "Psychological counseling"
        ],
        estimatedDuration: "Lifelong management is required.",
        followUpCare: "Regular check-ups to monitor growth, development, and hormone levels, typically every 6-12 months.",
        slug: "syndromes-turner-klinefelter",
        instagramLink: "https://www.instagram.com/explore/tags/turnersyndrome"
    },
];

export default treatmentsData;