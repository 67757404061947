import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import treatmentsData, { TreatmentData } from '../data/treatments';
import { Download } from 'lucide-react';
import InstagramEmbed from './InstagramEmbed';



const TreatmentTemplatePage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [treatment, setTreatment] = useState<TreatmentData | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const foundTreatment = treatmentsData.find(t => t.slug === slug);
    if (foundTreatment) {
      setTreatment(foundTreatment);
    } else {
      navigate('/treatments', { replace: true });
    }
  }, [slug, navigate]);

  if (!treatment) {
    return <div>Loading...</div>;
  }

  const sections = [
    { title: 'Symptoms', content: treatment.symptoms },
    { title: 'Causes', content: treatment.causes },
    { title: 'Treatments', content: treatment.treatments },
  ];

  return (
    <>
      <Helmet>
        <title>{`${treatment.title} | Dr. Swati Kanodia's Endocrinology Clinic`}</title>
        <meta name="description" content={treatment.description} />
      </Helmet>
      <motion.article 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4 py-12 max-w-4xl mt-28 mb-8 md:mt-32 lg:mt-48 lg:mb-20"
      >
         <div className="flex justify-between items-center mb-6">
          <motion.h1 
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-3xl md:text-4xl font-pt-serif-regular text-gray-800"
          >
            {treatment.title}
          </motion.h1>
          
          {treatment.pdfResource && (
            <motion.a
              href={treatment.pdfResource.url}
              download
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 shadow-md"
            >
              <Download className="w-5 h-5 mr-2" />
              <span className="hidden sm:inline">Download</span> Info Guide
            </motion.a>
          )}
        </div>

        <motion.div 
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="relative h-48 sm:h-64 md:h-80 lg:h-96 mb-8"
        >
          <img src={treatment.image} alt={treatment.title} className="w-full h-full object-contain rounded-lg shadow-lg" />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50 rounded-lg"></div>
          <motion.p 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="absolute bottom-4 left-4 right-4 text-white text-base sm:text-lg md:text-xl leading-relaxed"
          >
            {treatment.description}
          </motion.p>
        </motion.div>
        {sections.map((section, index) => (
          <motion.section 
            key={index}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 + index * 0.1, duration: 0.5 }}
            className="mb-6 bg-white rounded-lg shadow-md overflow-hidden"
          >
            <button
              className="w-full text-left px-4 sm:px-6 py-3 sm:py-4 bg-blue-100 hover:bg-blue-200 transition-colors duration-200"
              onClick={() => setActiveSection(activeSection === section.title ? null : section.title)}
            >
              <h2 className="text-xl sm:text-2xl font-semibold text-blue-800 flex justify-between items-center">
                {section.title}
                <motion.svg
                  animate={{ rotate: activeSection === section.title ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                  className="w-5 h-5 sm:w-6 sm:h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </motion.svg>
              </h2>
            </button>
            <AnimatePresence>
              {activeSection === section.title && (
                <motion.div 
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="px-4 sm:px-6 py-3 sm:py-4 bg-blue-50 overflow-hidden"
                >
                  <ul className="list-disc list-inside">
                    {section.content.map((item, itemIndex) => (
                      <motion.li 
                        key={itemIndex}
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: itemIndex * 0.1, duration: 0.3 }}
                        className="mb-2 text-gray-700 leading-relaxed"
                      >
                        {item}
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.section>
        ))}
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          className="mt-8 bg-white rounded-lg shadow-md overflow-hidden"
        >
          <h2 className="text-xl sm:text-2xl font-semibold text-blue-800 px-4 sm:px-6 py-3 sm:py-4 bg-blue-100">Additional Information</h2>
          <div className="px-4 sm:px-6 py-3 sm:py-4">
            <p className="mb-3 text-gray-700 leading-relaxed"><strong>Estimated Duration:</strong> {treatment.estimatedDuration}</p>
            <p className="text-gray-700 leading-relaxed"><strong>Follow-Up Care:</strong> {treatment.followUpCare}</p>
          </div>
        </motion.div>

        <InstagramEmbed url={treatment.instagramLink} />
      

      </motion.article>
    </>
  );
};

export default TreatmentTemplatePage;