import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import ScatteredBackground from '../ui/ScatteredBackground';

const SpecialtyServices: React.FC = () => {
  
  const navigate = useNavigate();
  
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const featuredService = {
    groupName: "Comprehensive Diabetes Care",
    description: "Our state-of-the-art diabetes management program combines cutting-edge technology with personalized care to help you effectively manage your condition and improve your quality of life.",
    icon: "🩺",
  };

  const otherServices = [
    { groupName: "Advanced Diagnostic Tests", icon: "🧪" },
    { groupName: "Customized Treatments", icon: "💊" },
    { groupName: "Obesity Management", icon: "🍎" },
  ];

  return (
    <ScatteredBackground darkColor='#25806a' lightColor='#f5fcfb'>
    <motion.section
      id="specialtyservices"
      className="mb-12 px-4 py-16"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-4 font-fraunces-slab text-teal-800">Our Specialty Services</h2>
        <p className="text-center mb-12 max-w-2xl mx-auto text-gray-600 font-work-sans">
          At Dr. Swati Kanodia's clinic, we offer a comprehensive range of specialized services tailored to address various endocrine disorders and metabolic conditions. Our team of experts is dedicated to providing personalized care and innovative treatments to help you achieve optimal health and well-being.
        </p>
        
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Featured Service Card */}
          <motion.div
            className="lg:w-1/2 bg-white rounded-2xl shadow-lg overflow-hidden"
            variants={cardVariants}
          >
            <div className="h-64 bg-teal-600 flex items-center justify-center">
              <img src="/images/blogs/blog-4.jpg" alt="Featured Service" className="w-64 h-64 object-center" />
            </div>
            <div className="p-6">
              <div className="text-4xl mb-4">{featuredService.icon}</div>
              <h3 className="text-2xl font-semibold mb-3 font-fraunces-slab text-teal-700">{featuredService.groupName}</h3>
              <p className="text-gray-600 mb-4">{featuredService.description}</p>
              <Link to="/services" className="inline-flex items-center text-sm font-semibold text-teal-600 hover:text-teal-800 transition-colors duration-300">
                Learn more <ArrowRight className="ml-1 w-4 h-4" />
              </Link>
            </div>
          </motion.div>

          {/* Other Services Cards */}
          <div className="lg:w-1/2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-1 gap-8">
            {otherServices.map((service, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-2xl shadow-lg p-6"
                variants={cardVariants}
              >
                <div className="text-4xl mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-3 font-fraunces-slab text-teal-700">{service.groupName}</h3>
                <Link to={`/services`} className="inline-flex items-center text-sm font-semibold text-teal-600 hover:text-teal-800 transition-colors duration-300">
                  Learn more <ArrowRight className="ml-1 w-4 h-4" />
                </Link>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="text-center mt-12">
          <button onClick={() => navigate("/services")} className="bg-teal-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg inline-flex items-center justify-center text-lg transition-all duration-300 hover:bg-teal-800 hover:scale-105">
            Explore All Services
          </button>
        </div>
      </div>
    </motion.section>
  </ScatteredBackground>
  );
};

export default SpecialtyServices;