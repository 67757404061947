import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Treatments from './pages/Treatments';
import PatientEducation from './pages/PatientEducation';
import TreatmentTemplatePage from './components/utilities/TreatmentTemplatePage';
import Contact from './pages/Contact';
import './index.css';
import BlogPostTemplate from './components/utilities/BlogPostTemplate';
import Gallery from './pages/Gallery';
import DietHandout from './pages/DietHandoutPage';
import MPHDPage from './components/utilities/MPHD';
import DiabetesInfoPage from './components/utilities/DiabetesPage';
import GestationalDiabetesPage from './components/utilities/GestationalDiabetes';



const useScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};



const App: React.FC = () => {

  useScrollToTop();

  return (
    <Layout>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/treatments' element={<Treatments />} />
        <Route path='/services' element={<Services />} />
        <Route path='/patient-education' element={<PatientEducation />} />
        <Route path='/treatments/:slug' element={<TreatmentTemplatePage />} />
        <Route path='/diabetes-info' element={<DiabetesInfoPage />} />
        <Route path='/gestational-info' element={<GestationalDiabetesPage />} />
        <Route path='/mphd' element={<MPHDPage />} />
        <Route path='/blogs/:slug' element={<BlogPostTemplate />} />
        <Route path='/treatments/diet-handout' element={<DietHandout />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/diet-handout' element={<DietHandout />} />

      </Routes>
    </Layout>
  );
}

export default App;
