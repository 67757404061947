import React, { useState } from 'react';
import { Calendar, Download } from 'lucide-react';

interface QuestionAnswer {
  question: string;
  answer: string | string[];
}

const MPHDPage: React.FC = () => {
  const sections: QuestionAnswer[] = [
    {
      question: "What is Multiple Pituitary Hormone Deficiency (MPHD)?",
      answer: [
        "Multiple Pituitary Hormone Deficiency (MPHD) occurs when there are low levels or deficiency of multiple hormones produced by the pituitary gland.",
        "These deficiencies can have dramatic effects on the body's growth and function, especially when more than one hormone is affected."
      ]
    },
    {
      question: "What are Hormones and their Functions?",
      answer: [
        "Hormones are chemicals that carry messages from one part of the body to another via the blood stream. They are produced by endocrine glands and regulate growth, development and metabolism.",
        "Key hormones include:",
        "- Growth hormone (GH): Makes children grow",
        "- Gonadotrophins (FSH & LH): Control puberty and fertility",
        "- Thyroid hormones (TSH): Control body's metabolism",
        "- Antidiuretic hormone (ADH): Controls urine production",
        "- Adrenocorticotrophic hormone (ACTH): Maintains blood cortisol levels"
      ]
    },
    {
      question: "What causes MPHD?",
      answer: [
        "Congenital (Present at birth):",
        "- Anterior Pituitary Hormone Deficiency",
        "- Panhypopituitarism",
        "- Septo-optic dysplasia",
        "Acquired (Develops later):",
        "- Trauma",
        "- Tumor",
        "- Irradiation"
      ]
    },
    {
      question: "What are the signs of MPHD?",
      answer: [
        "In Babies (Infants):",
        "- Prolonged jaundice",
        "- Low blood sugar levels",
        "- Poor growth",
        "- Lethargy",
        "- Dry skin",
        "In Children & Adolescents:",
        "- Poor growth due to lack of growth hormone",
        "- Poor growth, lethargy, dry skin and cold intolerance (thyroid hormone deficiency)",
        "- Hypoglycemia (cortisol and growth hormone deficiency)",
        "- Failure to enter puberty (gonadotrophin deficiency)"
      ]
    },
    {
      question: "How is MPHD diagnosed?",
      answer: [
        "Diagnosis involves:",
        "Blood tests:",
        "- To check the function of the pituitary gland",
        "Imaging:",
        "- Bone age X-ray",
        "- MRI Scan"
      ]
    },
    {
      question: "How is MPHD treated?",
      answer: [
        "Treatment involves replacing deficient hormones with synthetic versions. Different treatments include:",
        "- Growth hormone (GH): Given by injection to stimulate growth and boost energy",
        "- Gonadotrophin (FSH & LH): Boys receive testosterone injections, girls receive estrogen and progestogen tablets",
        "- Thyroid hormone (TSH): Daily tablet to treat underactive thyroid",
        "- Adrenocorticotrophic hormone (ACTH): Tablets to maintain correct blood pressure and sugar balance",
        "- Antidiuretic hormone (ADH): DDAVP tablets, intranasal drops or spray for water balance"
      ]
    }
  ];

  const [expandedSections, setExpandedSections] = useState<number[]>([]);

  const toggleSection = (index: number) => {
    setExpandedSections(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [...prev, index]
    );
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/MPHD.pdf';
    link.download = 'mphd-guide.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl mt-28 md:mt-48">
      <h1 className="text-3xl font-bold text-center mb-8 text-blue-800">
        Multiple Pituitary Hormone Deficiency (MPHD)
      </h1>
      
      <div className="space-y-4">
        {sections.map((section, index) => (
          <div 
            key={index} 
            className="border border-gray-200 rounded-lg shadow-sm"
          >
            <button 
              onClick={() => toggleSection(index)}
              className="w-full text-left p-4 bg-gray-100 hover:bg-gray-200 transition-colors flex justify-between items-center"
            >
              <h2 className="font-semibold text-lg text-blue-700">
                {section.question}
              </h2>
              <span className="text-xl">
                {expandedSections.includes(index) ? '−' : '+'}
              </span>
            </button>
            
            {expandedSections.includes(index) && (
              <div className="p-4 bg-white">
                {typeof section.answer === 'string' ? (
                  <p>{section.answer}</p>
                ) : (
                  section.answer.map((ans, i) => (
                    <p key={i} className={`mb-2 ${ans.startsWith('-') ? 'pl-4' : ''}`}>
                      {ans}
                    </p>
                  ))
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-8 space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center items-center">
        <button 
          onClick={handleDownload}
          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto"
        >
          <Download className="mr-2" size={20} />
          Download MPHD Guide
        </button>
        <button 
          onClick={()=> window.location.href = "/contact"}
          className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors w-full sm:w-auto"
        >
          <Calendar className="mr-2" size={20} />
          Book Appointment
        </button>
      </div>

      <div className="mt-6 sm:mt-8 text-center">
        <p className="text-xs sm:text-sm text-gray-600 italic">
          Consult Dr. Swati Kanodia for personalized medical advice.
        </p>
        <div className="mt-4 text-sm text-gray-700">
          <p>Contact: +91 9015550000</p>
          <p>Email: dr.swatikanodia@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default MPHDPage;