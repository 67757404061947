import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import AppointmentModal from "../components/utilities/AppointmentModal";
import { Video, Calendar} from "lucide-react";
import TestimonialCarousel from "../components/utilities/Testimonials";
import FAQItem from "../components/utilities/FAQItem";
import SpecialtyServices from "../components/utilities/SpecialityServices";
import HomeServices from "../components/utilities/HomeServices";
import HomeAbout from "../components/utilities/HomeAbout";
import ScatteredBackground from "../components/ui/ScatteredBackground";
import PadiatricEndocrinologyIntro from "../components/utilities/PadiatricEndocrinologyIntro";
import { useNavigate } from "react-router-dom";
import OfflineModal from "../components/utilities/OfflineModal";
import DietHandoutPage from "./DietHandoutPage";


const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  const navigate = useNavigate();

  const slides = [
    { position: "center", class: "object-center" },
    { position: "left", class: "object-left" },
    { position: "right", class: "object-right" },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);


  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };



  return (
    <main className="flex-grow">
   <section id="landing-hero" className="relative mt-28 md:mt-48">
      <div className="relative w-full h-screen max-h-screen overflow-hidden">
        {slides.map((slide, index) => (
          <motion.img
            key={index}
            src="/images/banner.jpg"
            alt={`Happy, healthy children ${slide.position}`}
            className={`absolute w-full h-full object-cover ${slide.class}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentSlide ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          />
        ))}
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="max-w-4xl mx-auto mt-24"
          >
            <h2 className="font-work-sans text-2xl sm:text-3xl md:text-4xl text-yellow-300 mb-4 font-bold">
              Nurturing Tomorrow's Health Today
            </h2>
            <h1 className="font-fraunces-slab text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-4 text-white">
              Dr. Swati Kanodia
            </h1>
            <p className="text-white text-xl md:text-2xl font-bold mb-2">
              Consultant - Pediatric & Adolescent Endocrinology, Gestational Diabetes
            </p>
            <p className="text-lg md:text-xl text-white mb-2 font-work-sans">
              MBBS, MD (Pediatrics),  Fellow (Ped Endocrinology),
            </p>
            <p className="text-lg md:text-xl text-white mb-6 font-work-sans">
             PGDD (Cardiff) , CCGDM
            </p>
          
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={openModal}
                className="bg-purple-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-purple-700 hover:scale-105 shadow-lg"
              >
                Book Appointment
                <Calendar className="ml-3 w-5 h-5" />
              </button>
              <button
                onClick={openModal2}
                className="bg-green-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-green-700 hover:scale-105 shadow-lg"
              >
                Virtual Consultation
                <Video className="ml-3 w-5 h-5" />
              </button>
            </div>
          </motion.div>
        </div>
      </div>
      <OfflineModal isOpen={isModalOpen} onClose={closeModal} />
      <AppointmentModal isOpen={isModalOpen2} onClose={closeModal2} />
     
    </section>

      <HomeAbout />
      <PadiatricEndocrinologyIntro />
      <HomeServices />
      <SpecialtyServices />
      <TestimonialCarousel />
   
      <ScatteredBackground darkColor='#ff99ff' lightColor='#faf7fa'>
        <motion.section
          id="faqs-and-map"
          className="mb-12"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <div className="container mx-auto px-4 py-20">
            <motion.h2
              className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 text-foreground font-fraunces-slab text-center"
              variants={itemVariants}
            >
              Expert Care for Your Child
            </motion.h2>
            <div className="flex flex-col lg:flex-row lg:gap-8">
              <motion.div className="w-full lg:w-1/2 mb-8 lg:mb-0" variants={itemVariants}>
                <motion.div className="space-y-6" variants={containerVariants}>
                  <motion.div variants={itemVariants}>
                    <FAQItem question="Who is Dr. Swati Kanodia?">
                      Dr. Swati Kanodia is a highly qualified Pediatric Endocrinologist with MBBS, MD (Pediatrics), and Fellowship in Child and Adolescent Endocrinology. She specializes in hormonal and growth disorders in children and adolescents.
                    </FAQItem>
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <FAQItem question="What types of child health issues does Dr. Kanodia treat?">
                      As a child expert, Dr. Kanodia treats a wide range of pediatric endocrine disorders including growth issues, early or delayed puberty, type 1 diabetes, thyroid disorders, ambiguous genitalia, rickets, and hormonal causes of obesity in children and adolescents.
                    </FAQItem>
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <FAQItem question="When should I consider consulting Dr. Kanodia for my child?">
                      You should consider a consultation if your child shows signs of abnormal growth, early or delayed puberty, excessive thirst or urination, unexplained weight loss, fatigue, or if you have any concerns about their hormonal health and development. Dr. Kanodia's expertise in child endocrinology ensures comprehensive care for your child's unique needs.
                    </FAQItem>
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <FAQItem question="What is Dr. Kanodia's approach to child and adolescent care?">
                      Dr. Kanodia's approach is centered on "Nurturing Growth, Balancing Hormones, Empowering Futures." She provides personalized care, considering each child's unique developmental needs, and works closely with families to ensure the best possible outcomes for her young patients.
                    </FAQItem>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                className="w-full lg:w-1/2"
                variants={itemVariants}
              >
                <div className="aspect-video">
                  <iframe
                    title="Dr. Swati Kanodia Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112049.2835354027!2d77.1036831933888!3d28.64354237221335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0364276e18f7%3A0xd1875cd7919117a1!2sDr.%20Swati%20Kanodia%2C%20Pediatric!5e0!3m2!1sen!2sin!4v1727710220835!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    className="w-full h-full"
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </motion.section>
      </ScatteredBackground>


      <motion.section
        id="contact"
        className="relative w-full h-screen min-h-[600px] overflow-hidden"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.img
          src="/images/contact.jpg"
          alt="Kidsendo Clinic"
          className="absolute w-full h-full object-cover object-center"
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10 }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
          <motion.div
            className="max-w-3xl"
          >
            <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-fraunces-slab">
              Take a Step Forward 
            </h2>
            <p className="text-xl sm:text-2xl md:text-3xl text-white mb-8 font-work-sans">
              Improving Your Child's Growth and Well-being
            </p>
            <button
              className="bg-green-500 hover:bg-red-600 text-white py-3 px-6 sm:py-4 sm:px-8 rounded-lg text-lg sm:text-xl transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
              onClick={() => navigate('/contact')}
            >
              Connect with Dr. Swati Now
            </button>
          </motion.div>
        </div>
      </motion.section>

    </main>
  );
};

export default Home;