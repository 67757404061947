// import React, { useEffect, useState } from 'react';

// declare global {
//   interface Window {
//     instgrm?: {
//       Embeds: {
//         process: () => void;
//       };
//     };
//   }
// }

// interface InstagramEmbedProps {
//   url: string;
//   maxRetries?: number;
//   retryInterval?: number;
// }

// const InstagramEmbed: React.FC<InstagramEmbedProps> = ({ 
//   url, 
//   maxRetries = 5, 
//   retryInterval = 2000 
// }) => {
//   const [retryCount, setRetryCount] = useState(0);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [initialLoadAttempted, setInitialLoadAttempted] = useState(false);

//   const loadInstagramEmbed = () => {
//     if (window.instgrm) {
//       window.instgrm.Embeds.process();
//       setIsLoaded(true);
//       return true;
//     }
//     return false;
//   };

//   const initializeEmbed = () => {
//     // Try to load if Instagram embed script is already present
//     if (loadInstagramEmbed()) {
//       // Even if initial load succeeds, schedule one reload after a delay
//       if (!initialLoadAttempted) {
//         setInitialLoadAttempted(true);
//         setTimeout(() => {
//           loadInstagramEmbed();
//         }, 1500); // 1.5 second delay for the guaranteed reload
//       }
//       return;
//     }

//     // Load Instagram embed script if not already present
//     const script = document.createElement('script');
//     script.src = '//www.instagram.com/embed.js';
//     script.async = true;

//     script.onload = () => {
//       loadInstagramEmbed();
//       // Schedule one reload after script loads
//       if (!initialLoadAttempted) {
//         setInitialLoadAttempted(true);
//         setTimeout(() => {
//           loadInstagramEmbed();
//         }, 1500);
//       }
//     };

//     script.onerror = () => {
//       if (retryCount < maxRetries) {
//         setTimeout(() => {
//           setRetryCount(prev => prev + 1);
//           document.body.removeChild(script);
//           initializeEmbed();
//         }, retryInterval);
//       }
//     };

//     document.body.appendChild(script);
//   };

//   // Initial load
//   useEffect(() => {
//     initializeEmbed();

//     // Cleanup function
//     return () => {
//       const scripts = document.getElementsByTagName('script');
//       for (let i = 0; i < scripts.length; i++) {
//         if (scripts[i].src.includes('instagram.com/embed.js')) {
//           document.body.removeChild(scripts[i]);
//           break;
//         }
//       }
//     };
//   }, [retryCount]);

//   // Add a separate effect to handle URL changes
//   useEffect(() => {
//     if (isLoaded && window.instgrm) {
//       window.instgrm.Embeds.process();
//     }
//   }, [url]);

//   return (
//     <div className="instagram-embed-container my-8">
//       {!isLoaded && (
//         <div className="flex justify-center items-center h-32">
//           <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
//         </div>
//       )}
//       <blockquote 
//         className="instagram-media" 
//         data-instgrm-captioned 
//         data-instgrm-permalink={url}
//         data-instgrm-version="14"
//         style={{ 
//           background: '#FFF',
//           border: 0,
//           borderRadius: '3px',
//           boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
//           margin: '1px',
//           maxWidth: '540px',
//           minWidth: '326px',
//           padding: 0,
//           width: 'calc(100% - 2px)',
//           display: isLoaded ? 'block' : 'none'
//         }}
//       >
//       </blockquote>
//     </div>
//   );
// };

// export default InstagramEmbed;

import React, { useEffect, useState, useRef } from 'react';

declare global {
  interface Window {
    instgrm?: {
      Embeds: {
        process: () => void;
      };
    };
  }
}

interface InstagramEmbedProps {
  url: string;
  maxRetries?: number;
  retryInterval?: number;
}

const InstagramEmbed: React.FC<InstagramEmbedProps> = ({
  url,
  maxRetries = 5,
  retryInterval = 2000
}) => {
  const [retryCount, setRetryCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialLoadAttempted, setInitialLoadAttempted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const loadInstagramEmbed = () => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
      setIsLoaded(true);
      return true;
    }
    return false;
  };

  const initializeEmbed = () => {
    if (loadInstagramEmbed()) {
      if (!initialLoadAttempted) {
        setInitialLoadAttempted(true);
        setTimeout(() => {
          loadInstagramEmbed();
        }, 1500);
      }
      return;
    }

    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;

    script.onload = () => {
      loadInstagramEmbed();
      if (!initialLoadAttempted) {
        setInitialLoadAttempted(true);
        setTimeout(() => {
          loadInstagramEmbed();
        }, 1500);
      }
    };

    script.onerror = () => {
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          document.body.removeChild(script);
          initializeEmbed();
        }, retryInterval);
      }
    };

    document.body.appendChild(script);
  };

  // Initial load
  useEffect(() => {
    initializeEmbed();

    return () => {
      const scripts = document.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.includes('instagram.com/embed.js')) {
          document.body.removeChild(scripts[i]);
          break;
        }
      }
    };
  }, [retryCount]);

  // Handle URL changes
  useEffect(() => {
    // Reset the container when URL changes
    if (containerRef.current) {
      // Clear the container
      containerRef.current.innerHTML = '';

      // Create new blockquote element
      const blockquote = document.createElement('blockquote');
      blockquote.className = 'instagram-media';
      blockquote.setAttribute('data-instgrm-captioned', '');
      blockquote.setAttribute('data-instgrm-permalink', url);
      blockquote.setAttribute('data-instgrm-version', '14');

      // Apply styles
      Object.assign(blockquote.style, {
        background: '#FFF',
        border: '0',
        borderRadius: '3px',
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
        margin: '1px',
        maxWidth: '540px',
        minWidth: '326px',
        padding: '0',
        width: 'calc(100% - 2px)',
        display: isLoaded ? 'block' : 'none'
      });

      // Append new blockquote
      containerRef.current.appendChild(blockquote);

      // Reset loading state
      setIsLoaded(false);

      // Reinitialize embed
      if (window.instgrm) {
        window.instgrm.Embeds.process();
        setIsLoaded(true);
      } else {
        initializeEmbed();
      }
    }
  }, [url]);

  return (
    <div className="instagram-embed-container my-8 flex justify-center items-center w-full">
      <div className="w-full max-w-[540px]">
        {!isLoaded && (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        )}
        <div ref={containerRef} className="flex justify-center" />
      </div>
    </div>
  );
};

export default InstagramEmbed;